$val: 1;
$values: 0 0 100px;
$border-radius: 2px;
$border-radius-half: 1px;

$box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1), 2px 2px 3px rgba(0, 0, 0, 0.25);
$box-shadow3: 3px 3px 3px rgba(0, 0, 0, 0.4), 3px 3px 4px rgba(0, 0, 0, 0.3);
$box-shadow5: 2px 2px 3px rgba(0, 0, 0, 0.2), 3px 3px 5px rgba(0, 0, 0, 0.35);
$box-shadow-article: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

$box-shadow-gray: 1px 1px 2px rgba(43, 43, 43, 0.10), 2px 2px 3px rgba(43, 43, 43, 0.20);

$box-shadow-a: 1px 1px 1px rgba(255,255,255, 0.15), 2px 2px 3px rgba(255,255,255, 0.20);
$box-shadow-a3: 2px 2px 2px rgba(255,255,255, 0.2), 3px 3px 4px rgba(255,255,255, 0.3);

$drop-shadow: drop-shadow(1px 1px 0 rgba(255, 255, 255, .7));
$drop-shadow3: drop-shadow(3px 3px 3px rgba(255, 255, 255, .7));
$drop-shadow5: drop-shadow(.5rem .5rem 1rem rgba(255, 255, 255, .85));

$text-shadow: rgba(43, 43, 43, 0.5) 0.15em 0.15em 4px ;
$text-shadow-drop: rgba(43,43,43,0.7);
$text-shadow-drop2: rgba(225, 67, 68, 0.4) .09em .05em;

$ubatuba: #68766D;
$ubatuba--10: rgba(104,118,109, .1);

// $amazon: #0d4773; //13 71 115

$gray: #e7e7e7; //231 231 231
$gray-10: #f5f5f5; // 245 245 245;
$gray-20: #DCDCDC; //220 220 220;
$gray-30: #c6c6c6;// ;
$gray-40: #949494; //148 148 148
$gray-50: #C0C0C0;// 192 192 192;
$gray-60: #646464; // 100 100 100
$gray-80: #2b2b2b; // 43 43 43
$gray-90: rgb(20,20,20);
$gray-95: rgba(20,20,20,0.95);


$gray--20: rgba(43,43,43,0.2); // transparent
$gray--40: rgba(43,43,43,0.4); // transparent
$gray--50: rgba(43,43,43,0.5); // transparent
$gray--60: rgba(43,43,43,0.6); // transparent
$gray--80: rgba(43,43,43,0.8); // transparent
$gray--90: rgba(43,43,43,0.9);

// $background: #e7e5e3;
$background--10: rgba(59, 100, 135, 0.1);
$background--20: rgba(59, 100, 135, 0.2);
$background--40: rgba(59, 100, 135, 0.4);
$background--50: rgba(59, 100, 135, 0.5);
$background--60: rgba(59, 100, 135, 0.6);
$background--70: rgba(59, 100, 135, 0.7);
$background--80: rgba(59, 100, 135, 0.8);
$background: rgba(59, 100, 135, 1);

$yesterday: rgba(42, 94, 140);
$yesterday--20: rgba(42, 94, 140, 0.2);
$yesterday--30: rgba(42, 94, 140, 0.3);
$yesterday--40: rgba(42, 94, 140, 0.4);
$yesterday--50: rgba(42, 94, 140, 0.5);
$yesterday--60: rgba(42, 94, 140, 0.6);
$yesterday--70: rgba(42, 94, 140, 0.6);
$yesterday--80: rgba(42, 94, 140, 0.8);

// beige with transparency
$beige-light--10: rgba(208, 209, 205, .1);
$beige-light--30: rgba(208, 209, 205, .3);
$beige-light--70: rgba(208, 209, 205, .7);
$beige-lighter--40: rgba(239, 240, 235, .4);
$beige-lighter--60: rgba(239, 240, 235, .6);
$beige-lighter--80: rgba(239, 240, 235, .8);

$today: rgb(253, 176, 10);
$today--20: rgba(253, 176, 10, 0.2);
$today--30: rgba(253, 176, 10, 0.3);
$today--40: rgba(253, 176, 10, 0.4);
$today--50: rgba(253, 176, 10, 0.5);
$today--60: rgba(253, 176, 10, 0.6);
$today--70: rgba(253, 176, 10, 0.7);
$today--80: rgba(253, 176, 10, 0.8);
$today--90: rgba(253, 176, 10, 0.9);

$tomorrow: #e14344;
$tomorrow--20: rgba(225, 67, 68, 0.2);
$tomorrow--30: rgba(225, 67, 68, 0.3);
$tomorrow--40: rgba(225, 67, 68, 0.4);
$tomorrow--50: rgba(225, 67, 68, 0.5);
$tomorrow--60: rgba(225, 67, 68, 0.6);
$tomorrow--70: rgba(225, 67, 68, 0.7);
$tomorrow--80: rgba(225, 67, 68, 0.8);
$tomorrow--90: rgba(225, 67, 68, 0.9);
$am2: #e0e0e0;
$am: #c7c9c8;
$pm: #dcdedd;
$midday: #6e6e6e;
$noon: #555555;
$eve: #2d2d2d;