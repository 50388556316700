@charset "utf-8";
@import "./variables.scss";

.site-footer{
  display: flex;
  background-color: $gray--40;
  height: 45px;
  width: 100%;
  bottom: 0;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 100%;
  z-index: 25;
  a{
    max-height: 45px;
    .footer-logo{
      padding-top: 0.1em;
      padding-bottom: 0.2em;
      padding-left: 3em;
      width: 210px;
      object-fit:cover;
      height: 45px;
    }
  }

  .footer-dept{
    padding-right: 5em;
    padding-left: 2em;
    font-size: .9rem;
    line-height: 1.2rem;
    color: $gray-80;
  }
}