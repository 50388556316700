@charset "utf-8";
// @import "../config/paths.js";
@import "./variables.scss";

@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Anton&family=Bree+Serif&family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&family=Nova+Square&family=Oswald:wght@200;400;700&family=Raleway:wght@300;400;600;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

//use this to place font family in scss:
// font-family: 'Alfa Slab One', cursive;
// font-family: 'Anton', sans-serif;
// font-family: 'Bree Serif', serif;
// font-family: 'Nova Square', cursive;
// font-family: 'Libre Caslon Text', serif; font-weight: 0,400;0,700;1,400;
// font-family: 'Oswald', sans-serif; font-weight: 200,400,700;
//font-family: 'Raleway', sans-serif;

html,
body,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: "Oswald", sans-serif;
  font-size: 1rem;
  font-weight: 200;
  text-decoration: none;
  color: $today;
  background-color: transparent;
  box-sizing: border-box;
}
h3{
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 1.2em;
  color: $ubatuba;
}
h2{
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 1em;
  color: $ubatuba;
}
p {
  font-family: 'Libre Caslon Text', serif;
  font-weight: 400;
}
p > {
  span{
  font-family: 'Libre Caslon Text', serif;
  font-weight: 400;
  font-style: italic;
  }
}
ul {
  list-style: none;
}
input,
select,
textarea {
  margin: 0;
}
a{
  color: $tomorrow;
  text-decoration: none;
  &:hover, &:active{
    color: $today;
  }
}
*, *:before, *:after {
  box-sizing: inherit;
}
img.none {
  display: none;
}
.none {
  display: none;
}
.spacer{
  margin: 1rem 0;
}

.content-area {
  display: flex;
  flex-direction: column;
}



@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.transitionMounted{
  animation: "inAnimation 250ms ease-in"
};
.transitionUnmounted {
 animation: "outAnimation 270ms ease-out"
};

.App {
  align-items: center;
  justify-content: center;
  background-color: $am;
  color: $pm;
  background-image: url("../assets/images/webb.png");
  background-position: top left, center center;
  background-attachment: fixed, fixed;
  background-size: auto, auto;
  min-height: 100vh;
  margin: auto;
}

@media only screen and (max-width: 1250px) {

}

/* Medium screens */
@media all and (max-width: 850px) {
    .panel {
      display: flex;
      flex-direction: column;
      font-size: .9rem;
    }

    .menu-container {
      /* When on medium sized screens, we center it by evenly distributing empty space around items */
      justify-content: space-around;
    }
}

/* Small screens */
@media all and (max-width: 600px) {
  .menu-container {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }
}


@import "./article.scss";
@import "./page-submenu.scss";
@import "./footer.scss";
@import "./navigation.scss";
@import "./home.scss";

// @import "./mixins.scss";