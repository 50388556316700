@charset "utf-8";
@import "./variables.scss";


// classes in use: content-area >
  //panel-content-area + panel${id} >
    //panel + data-active::before >
      //panelContent >
        //a > linkText
        //panelTop >
          //(panelTitle, panelFirstName, panelLastName, panelText + showText,
        //panelImage + panel${id}

.panel-content-area {
  display: flex;
  height: 82vh;
  flex-direction: row;
  justify-content: space-evenly;
  padding:10vh 2em 3em 2em;
  margin: 3em 0 2em 0;
  width: 100vw;

  .panel{
    display: flex;
    flex: 1 0 15%;
    z-index: 20;
    margin: 1em;
    flex-direction: column;
    height: 55vh;
    border: 1px solid $am;
    border-radius: 1em;
    background: radial-gradient($today--60, $today--90);
    box-shadow: .5em .5em 0.5em rgba(0, 0, 0, 0.3),
    .2em 0.2em 0.8em rgba(0, 0, 0, 0.3);

    //.polycard
    perspective: 600px;

    &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 0 0 1em 1em;
        width: 100%;
        height: 50%;
        transform-origin: center top;
        transform: rotateX(180deg);

        background:
        $today--60
        linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.4));
        transition: 0.7s ease-in-out  transform;
    }

    &:hover::before {
        transform: rotateX(0);
        color: $today--40;
    }

    &.active {
      &::before {
        transform: rotateX(0);
      }
      .panelContent {
        .panelTop {
          .panelLastName, .panelFirstName, .panelText {
            color: $eve;
          }
        }
        .panelImage{
          filter:  blur(2px) opacity(.6);
        }
      }
      &:hover::before {
        transform: rotateX(180);
      }
    }

    .panelContent {
      height: 50%;
      color: $noon;
      transition:
        color 0.4s ease-in-out,
        filter 0.9s ease-in-out;

      .panelTop{
        display: flex;
        flex: 0 1 25%;
        flex-direction: column;
        margin: 2em 1em 0.5em 1em;

        .panelTitle {
          font-family:'Nova Square', sans-serif;
          font-size: 1em;
        }
        .panelFirstName {
          font-family:'Nova Square', sans-serif;
          font-size: 1.4em;
          padding: .3em 0 0 0;
        }
        .panelLastName {
          font-family: 'Raleway', sans-serif;
          font-weight: 900;
          font-size: 1.9em;
          line-height: 1.5;
          text-shadow: .2em .2em .2em rgba(45, 45, 45, 0.5);
        }
        .panelText {
          font-family:'Nova Square', sans-serif;
          font-size: 1.1em;
          line-height: 1.3 ;
          padding: .5em 0 .5em 0;
          &.showText{
            color: $noon;
          }
        }
      }

      .linkText {
        display: flex;
        float: right;
        align-self: flex-start;
        margin: 1em .5em;
        padding: .5em;
        color: $am;
        background-color: $tomorrow--70;
        transition:
          color 0.3s ease-in,
          background-color 0.3s ease-in ;

        .material-symbols-outlined {
          font-variation-settings:
          'FILL' 0,
          'wght' 900,
          'GRAD' 0,
          'opsz' 48
        }
        &:hover{
          color: $gray-60;
          background-color: $tomorrow;
        }
      }

      &:hover{   //hover over panelContent
        cursor: context-menu;

        .panelImage{
          filter:  blur(1px) opacity(.6);
        }
      }

    .panelImage{
      position: absolute;
      height: 50%;
      width: 100%;
      top: 50%;
      padding:1em 2em;
      background-size: cover;
      background-position: center center;
      overflow: hidden;
      border-radius: 0 0 1em 1em;
      z-index: 60;

      // &:hover::before{
        // filter: blur(1px) brightness(.8) opacity(.6);
        // filter: blur(2px);
      // }

    }


    } // end of panel-content
  } // end of panel
} //end panel-content-area


.elahehahmadi{
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  background-image: url("../assets/images/flame-semiconductor.jpg");
  // background-image: url("https://source.unsplash.com/WqhP-Oq2aVY/1500x1500"); //encounter glass wall
}
.ianroberts{
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  background-image: url("/assets/images/wireless-network-nick-fancher.jpg");
}
.xiaofancui{
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  background-image: url("/assets/images/hex-computer-grid-steve-johnson.jpg");

}
.frankchangmedal{
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  background-image: url("/assets/images/Maxwell-statue.jpg");
}
.kangwanginventors{
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  background-image: url("/assets/images/microprocessor-zhvgeirSXgU.jpg");
  // background-image: url("https://source.unsplash.com/CUY_YHhCFl4/1500x1500");

}
.kadambiaward{
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  background-image: url("/assets/images/dark-skin-doctor.jpg");
}



@media only screen and (max-width: 1200px) {
  .panel {
    font-size: 0.9em;
  };
}
@media only screen and (max-width: 900px) {
  .panel-content-area{
    flex-direction: column;
    .panel {
      flex-direction: row;
      max-height: 14%;
      filter: grayscale(0.1);
      .panelContent {
        top: 1em;
        flex-direction: row;
        margin: 0 0 0 .5em;
        .panelTop{
          margin: 0;
        }
        // .panelText {
        //   top: 2rem;
        //   margin: 0;
        //   visibility: visible;
        // }
      }

      &:hover{
        transition: filter 0.3s ease-in-out 0.3s;
        filter: grayscale(0.1);
      }
    }
  }

  .article {
    margin-left: 4%;
    margin-right: 4%;
  }
}
