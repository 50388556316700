@charset "utf-8";
@import "./variables.scss";

.grid-layout {
  display: grid;
  grid-gap: 2em;
  // width: 100%;
  grid-auto-flow: row;
  // grid-template-columns: repeat(auto-fill, minmax(325px, 4fr)) ;
  // grid-template-rows: repeat(2, 2fr);
  // padding: 1em;

  .full {
    // grid-template-columns: 1fr ;
    grid-auto-flow: row;
    align-content: start;
    grid-gap: 1em;
    justify-content: space-between;
    margin-bottom: 2em;
  }

  h3.page-title{
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 2.2em;
    color: $gray-40;
    text-indent: 0;
    margin: 0.5em 0 0.5em 0;
    background: transparent;
  }

  h4.page-subtitle{
    font-family: 'Libre Caslon Text', serif;
    font-weight: 400;
    font-size: 1em;
    text-align: justify;
    text-justify: inter-word;
    text-indent: 0;
  }

  .page-award{
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 1.3em;
    margin-bottom: 0.5em;
    text-indent: 0;
    text-align: left;
  }
  .page-name{
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 1.3em;
    margin-bottom: 0.5em;
    text-indent: 0;
  }
  .page-text{
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 1em;
    margin-bottom: 1em;
    text-indent: 0;
    text-align:justify;
    text-justify: inter-word;
  }

  .page-caption{
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 1.5em;
    text-indent: 0;
    text-align: left;
    color: $gray-40;
  }

  .page-img{
    display: grid;
    grid-template-columns: 1fr;
    text-indent: 0;
    overflow: hidden;
    max-width: 450px;
    max-height: 550px;

    img{
      width: 450px;
      height: 550px;
      object-position: 50% 50%;
      object-fit: contain;
    }
  }

  //page speciffic css

  .student-center-frame{
    text-indent: 0;
    max-width: 650px;
    max-height: 150px;

    img{
      object-fit: contain;
      object-position: 0% 0%;
      background-color: transparent;
      // margin: 0 0 2em 0;
      // overflow: hidden;
    }
  }

  img.full-width-img {
    width: 100%;
    max-height: 180px;
    float: left;
    object-fit: cover;
    object-position: 50% 45%;
    overflow: hidden;
    margin-bottom: 1.5em;
  }

  .prof-pics{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px , 4fr));
    grid-gap: 2em;
    padding: 0;
    text-indent: 0;

    img.fac{
      width: 150px;
      height: 190px;
      object-fit: cover;
      object-position: 50% 50%;
      overflow: hidden;
      margin-bottom: 0.4em;
      transition: all 0.3s;
      box-shadow: $box-shadow3;
      border-radius: $border-radius-half;
      border: 1px solid  $gray-60;
    }
    img.fac:hover{
      box-shadow: $box-shadow5;
    }
  }

  .researchcenters {
    display: grid;
    grid-gap: 3em;
    grid-template-columns: repeat(auto-fill, minmax(320px, 4fr));
    grid-template-rows: auto ;
    width: 100%;
    align-items: start;
    justify-content: space-between;

    .research-center{
      background-color: ubatuba--10;
      font-size: 1.1em;
      margin-bottom: 1.5em;
      font-family: 'Oswald', sans-serif;
      font-weight: 400;
      font-size: 1.5em;
      margin-bottom: 1em;
      text-indent: 0;
      text-align: left;

      .research-logo-frame{
        font-family: 'Oswald', sans-serif;
        font-weight: 200;
        font-size: 0.6em;
        color: $gray-80;
        box-shadow: $box-shadow-a;
        transition: all 0.3s ease-in-out;
        width: 250px;
        height: 250px;
        background-color: white;
        border-radius: $border-radius;
        margin: 0 3em 1em 0;

        img.research-logo{
          object-fit: contain;
          object-position: 50% 50%;
          max-width: 250px;
          max-height: 250px;
        }
        img.research-logo.cnsi{
          width: 250px;
          height: 250px;
        }
        img.research-logo.tanms{
          object-fit: contain;
          width: 250px;
          height: 250px;
        }

        &:hover{
            box-shadow: $box-shadow-a3;
        }
      }

      .research-name{
        font-family: 'Oswald', sans-serif;
        font-weight: 400;
        font-size: 0.9em;
        margin-bottom: 0.3em;
        text-indent: 0;
        text-align: left;
      }
      .research-prof{
        font-family: 'Oswald', sans-serif;
        font-weight: 200;
        font-size: 0.9em;
        text-indent: 0;
        text-align: left;
      }
    }

    .picture-area.none.researchcenters {
      display: none;
      left: 9999px;
      top: 9999px;
      max-height: 1px;
    }
    .none.researchcenters {
      display: none;
      left: 9999px;
      top: 9999px;
      max-height: 1px;
    }
  }
  .faculty-caption{
    margin-bottom: 2em;
    color: $gray-40;
  }

}

@media only screen and (max-width: 1200px) {
  .grid-layout {
    grid-template-columns: 1fr;
    .full {
      grid-column: span 1;
      grid-auto-flow: column;
    }
    .page-img{
      width: 300px;
      height: 400px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .grid-layout {
    grid-template-columns: 1fr;
    .full {
      grid-column: span 1;
      grid-auto-flow: column;
    }
    img.fac{
      width: 115px;
      height: 180px;
    }
    .page-img{
      width: 250px;
      height: 320px;
    }
  }
}
