@charset "utf-8";
@import "./variables.scss";

.grid-cards{
  display: grid;
  grid-gap: 1.1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  padding: 1em;
  max-width: 100%;

  .full,
  .funding {
    grid-template-columns: 1fr ;
    grid-auto-flow: row;
    grid-gap: 1em;
    justify-content: space-between;
    margin-bottom: 2em;
    }

  .card-deck
  {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: auto ;
    grid-gap: 1.5em;
    text-indent: 0;

    .card {
      box-shadow: $box-shadow;
      transition: all 0.3s ease-in-out;
      min-width: 320px;
      min-height: 300px;
      border-radius: $border-radius;
      border: 1px solid $gray-60;
      align-items: start;
      padding: 1.2em;
      max-width: 100%;
      background-image: url("../assets/images/grid.png");


      .frame{
        justify-self: center;
        border-radius: $border-radius-half;
        border: 1px solid $gray-80;
        align-items: center;
        height: 190px;
        max-width: 150px;
        margin: 1em auto 1.5em auto;
        overflow: hidden;
        position: relative;
        background-color: $gray-40;

        img.card-pic-fellowship{
          border: 0px ;
          width: 150px;
          height: 190px;
          object-fit: cover;
          object-position: 50% 50%;
          justify-self: center;
        }
        img.card-pic{
          border: 0px ;
          width: 150px;
          height: 190px;
          object-fit: cover;
          object-position: 50% 50%;
          justify-self: center;
        }
      }

      img.logo-pic{
        border: 1px solid black  ;
        margin:0.4rem;
        background-color: $gray-60;
        width: 95%;
        max-height: 190px;
        object-fit: contain;
        object-position: 50% 50%;
        justify-self: center;

        &.cover{
          object-fit: cover;
        }
      }

      &:hover {
        transition: all 0.3s ease-in-out;
        box-shadow: $box-shadow3;
      }
    }     // END of CARD

    .card-partner{
      display: grid;
      grid-auto-flow: row;
      box-shadow: $box-shadow;
      transition: all 0.3s ease-in-out;
      min-width: 320px;
      min-height: 200px;
      border-radius: $border-radius-half;
      border: 1px solid $gray-60;
      background: $today--30;
      align-items: center;
      padding: 1em;

      img.logo-pic{
        border: 0px ;
        background-color: transparent;
        width: 95%;
        height: 190px;
        object-fit: contain;
        object-position: 50% 50%;
        justify-self: center;
        &.cover{
          object-fit: cover;
        }
      }

      &:hover {
        box-shadow: $box-shadow3;
      }
    }
  }  // end of card-deck


  .card-students{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20em, 3fr));
    grid-template-rows: auto ;
    grid-gap: 1.5em;
    text-indent: 0;

    .card{
      display: grid;
      background: $tomorrow--30;

       .student-frame{
         margin: 2em auto ;
         overflow: hidden;
         max-width: 20em;
         max-height: 25em;
         align-self: end;

         img{
           width: 20em;
           height: 25em;
           object-position: 50% 50%;
           object-fit: cover;
         }
       }
    }
  }

  .card-credits{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto ;
    grid-gap: 1.5em;
    text-indent: 0;
    max-width: 100%;

    .card{
      box-shadow: $box-shadow;
      transition: all 0.3s ease-in-out;
      border-radius: $border-radius;
      border: 1px solid $gray-60;
      background: $today--30;
      align-items: start;
      padding: 1.2em;
      max-width: 100%;
      color: $gray-80;

      height: fit-content;
      padding: 1.2em;

      .twocol{
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      &:hover {
        transition: all 0.3s ease-in-out;
        box-shadow: $box-shadow3;
      }
    }
  }

  h3.page-title{
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: $gray-80;
    font-size: 2.2em;
    text-indent: 0;
    margin: 0.5em 0 0.5em 0;
    background: transparent;
  }

  .card-title{
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 1.5em;
    color: $gray-60;
    text-align: center;
    margin: 2rem;
  }

  .card-name{
    line-height: 2.5;
    font-family: 'Alfa Slab One', sans-serif;
    font-size: 1.45em;
    color: $gray-80;
    text-align: center;
    margin: 0.5em auto;
  }

  .prof-name{
    font-family: 'Raleway', sans-serif;
    font-size: 1.4em;
    color: $gray-80;
    text-align: center;
    margin: 0.4em 0 0.4em 0;
  }
  .prof-area{
    font-family: 'Raleway', sans-serif;
    font-size: 1.1em;
    color: $gray-80;
    text-align: center;
    margin-bottom: 0.2em;
  }
  .prof-title{
    font-family: 'Libre Caslon Text', serif;
    font-style: italic;
    font-weight: 400;
    font-size: 0.9em;
    color: $gray-80;
    text-align: center;
    margin: 0 0 .2em 0;
  }
  .card-advisor{
    font-family: 'Raleway', sans-serif;
    font-size: 1em;
    color: $gray-80;
    text-align: center;
    margin-bottom: 0.2em;
  }

  .courses{
    font-family: 'Oswald', sans-serif;
    font-weight: 200;
    font-size: 1em;
    margin: 1em 0 0 2em;
    text-indent: 0;
    text-align: justify;
    text-justify: inter-word;
  }
  .courses-title{
    font-family: 'Oswald', sans-serif;
    font-weight: 200;
    font-size: 1em;
    margin: 0 0 2em 2em;
    text-indent: 0;
    text-align: justify;
    text-justify: inter-word;
  }

  li,
  .card-bottom > p{
    font-family: 'Oswald', sans-serif;
    font-weight: 200  ;
    font-size: .9em;
    color: $gray-80;
    text-align: left;
    text-decoration: none;
  }

  img.card-pic.book{
    border-radius: $border-radius-half;
    object-fit: cover;
    object-position: 50% 50%;
    max-width: 150px;
    width: 150px;
    height: 190px;
  }

  .frame-book{
    margin: 1em auto 1.5em;
    border: 1px solid $gray-80;
    align-items: center;
    width: 150px;
    height: 190px;
    overflow: hidden;
    position: relative;
  }

  .frame-prof-book{
    position: relative;
    display: none;
    transition: all 5.3s ease-in-out;
    border: 1px solid $gray-80;
    border-radius:  $border-radius-half;
    overflow: hidden;
    z-index: 10;
    top: 35px;
    right: 10px;
    width: 150px;
    height: 150px;
    transition: all 3.3s ease;
    box-shadow: $box-shadow;
  }

  .card.book{
    object-position: 50% 50%;
    transition: all 0.3s ease-in-out;
  }

  p > {
    span {
      font-weight: bold;
      font-style: italic;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .frame {
    max-height: auto;
  }
  .grid-cards{
    grid-template-columns: 1fr;
    .card-deck{
      grid-template-columns: 1fr;
    }
    .card-credits {
      .card {
        .twocol{
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
