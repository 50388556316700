@charset "utf-8";
@import "./variables.scss";

table{
  border-collapse: collapse;
  width: 100%;
  min-width: 350px;
  vertical-align: center;
  color:$am;
  background-color: $yesterday--50;
  background-image: url("../assets/images/y-so-c.png");
}

th{
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 1.4em;
  text-indent: 0;
  padding: 1.5em;
  border-bottom: 1px solid $midday;
  background-color: $yesterday--50;
  color:$am2;
}

tr td {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 1.2em;
  align-self: center;
  padding: 0.7em;
  padding-left: 1.5em;
  border-bottom: 1px solid $midday;
  color: $am;
  background-color: $yesterday--30;
}
// tr:nth-child(even) {
//   background-color: $yesterday--20;
// }
td.right{
  text-align: right;
  padding-right: 2em;
}
tbody tr:hover {
  background-color: $yesterday--60;
}

tfoot tr{
  align-items: center;
  background-color: $yesterday--20;
  height: 1em;
}
tr.t-total{
  color:$am2;
  background-color: darken($tomorrow--80, 10);
  &:hover{
    background-color: darken($tomorrow--60, 10) ;
  }
}

tr.sub-head{
  background-color: $tomorrow--70;
  &:hover{
    background-color: darken($tomorrow--50, 10) ;
  }
}
tr.sub-total{
  color:$am2;
  background-color: darken($tomorrow--60, 8);
  &:hover{
    background-color: lighten($tomorrow--70, 5) ;
  }
}

.table-overview {
  // overflow-y: scroll; //responsive
  // background-color: transparent;
  border-radius: $border-radius-half;
  box-shadow: $box-shadow;
  transition: all 0.3s;
  min-width: 350px;
  height: fit-content;
  width: 100%;
}
.wider-table{
  min-width: 650px;
}
.fullwidth-table{
  width: 100%;
  min-width: 95%;
}

ul, li, .card-bottom{
  background-color: transparent;
}

@media only screen and (max-width: 900px) {
  .wider-table{
    min-width: 95%;
  }

}
